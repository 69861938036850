import AxiosRequest from "../../utils/axios-request"
import SecureLS from 'secure-ls';

export default (uid, product, image, price, p_usd, usd, coin) ->
  console.log 'coin', coin
  id = parseInt product.idproduct
  price_usd_cop = if usd then price else p_usd
  final_data = {
    title: product.title
    image: image
    limit: product.units_available
    price_cop: price
    price_usd: p_usd
    price: price_usd_cop
    quantity: parseInt @quantity
    idproduct: id
  }
  AxiosRequest('/customers/shopping_carts/add.json', { product: id, quantity: @quantity }, 'post').then ({ data }) =>
    ls = new SecureLS();
    actual_cart = ls.get('cart');
    # inicializador cart
    if !actual_cart
      actual_cart = {}

    if !actual_cart[uid]
      actual_cart[uid] = []
    # verificamos que el producto que estamos agregando exista en el Carrito

    cart_some = actual_cart[uid] and actual_cart[uid].some((item) => (item.idproduct == id))
    if cart_some
      # Si exite el producto lo recorremos y actulizamos las unidades
      for item, value of actual_cart[uid]
        if value.idproduct == id
          # Validar que la cantidad no sea mayor a la limite
          if value.quantity < product.units_available
            value.quantity += parseInt @quantity
            Swal.fire({
              title: data.message,
              icon: 'success',
              showConfirmButton: false,
              allowOutsideClick: false
              inputAttributes:
                autocapitalize: 'off'
              footer: "
                <div class='uk-flex uk-flex-wrap uk-flex-around'>
                  <a class='uk-button black-button uk-margin-right' href='/customers/shopping_carts?coin=#{coin}'>Ir al carrito</a>
                  <a class='uk-button blue-button ' href='/productos?coin=#{coin}' data-turbolinks='false'>Ver más productos</a>
                </div>
              "
            })
          else
            Swal.fire({
              icon: 'error',
              title: "Error",
              text: "Número de unidades no válido (Unidades disponibles: #{product.units_available})"
            })
    else
      # Sino lo agregamos al carrito
      actual_cart[uid].push(final_data)
      Swal.fire({
        title: data.message,
        icon: 'success',
        showConfirmButton: false,
        allowOutsideClick: false
        inputAttributes:
          autocapitalize: 'off'
        footer: "
          <div class='uk-flex uk-flex-wrap uk-flex-around'>
            <a class='uk-button black-button uk-margin-right' href='/customers/shopping_carts?coin=#{coin}'>Ir al carrito</a>
            <a class='uk-button blue-button' href='/productos?coin=#{coin}' data-turbolinks='false'>Ver más productos</a>
          </div>
        "
      })
    ls.set('cart', actual_cart)
