import {get} from 'axios'
import {debounce} from 'throttle-debounce'
import UniqBy from 'lodash.uniqby'

export default debounce(1000, false, (mode = 'get', $state) ->
  @loading = true
  {
    categories
    discounts
    keywords
    subcategories
    subcategory_types
    preservations
    price_from
    price_to
    novelties
  } = @search_filters
  q = {
    products_with_discount: discounts if discounts
    novelty_true: novelties if novelties
    category_id_in: categories if categories.length
    preservation_id_in: preservations if preservations.length
    title_or_tags_name_cont_all: keywords.split(' ') if keywords
    subcategory_id_in: subcategories if subcategories.length
    subcategory_type_id_in: subcategory_types if subcategory_types.length
    public_price_published_price_gteq: price_from if price_from
    public_price_published_price_lteq: price_to if price_to
  }
  request = get('/products/search.json', { params: { q, @page, sort: [@sort] } })
  if mode == 'infinite'
    request.then ({data}) =>
      @loading = false
      quantity = data.products.length
      new_products = @products
      new_products.push(data.products...)
      @products = UniqBy(new_products, 'idproduct')
      if (quantity < 15)
        $state.complete()
        @page = 0
      else
        @page++
        $state.loaded()
  if mode == 'get'
    @products = []
    request.then ({data}) =>
      @page++
      @products = data.products
      @loading = false
)
