#External Libraries
import InfiniteLoading from 'vue-infinite-loading'
import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks'
import VueAutonumeric from 'vue-autonumeric'
import UniqBy from 'lodash.uniqby'

# Local imports
import fetchProducts from '../methods/products/fetch-products'
import AddCart from '../methods/products/add_cart'
import Favorite from '../methods/products/favorite'
import toCurrency from '../filters/to_currency'
import AxiosRequest from "../utils/axios-request"

Vue.use(TurbolinksAdapter)
document.addEventListener 'turbolinks:load', () ->
	el = document.getElementById 'index-product'
	return false unless el?

	url_mode = (property) ->
		resp = false
		resp = el.dataset[property] if el.dataset.hasOwnProperty(property)
		resp
	data = ->
		{ searchParams } = new URL(window.location.href)
		# Default values
		categories = []
		subcategories = []
		subcategory_types = []
		preservations = []

		page = 1
		novelties = 0
		discounts = window.location.pathname == '/descuentos'
		sort =
			public_price_published_price: 'desc'
			updated_at: false
			title: false

		# Url params
		categories_param = parseInt(url_mode('category') || searchParams.get('category'))
		subcategories_param = parseInt(url_mode('subcategory') || searchParams.get('subcategory'))
		subcategory_types_param = parseInt(url_mode('subcategory_type') || searchParams.get('subcategory_type'))
		preservations_param = parseInt(url_mode('preservation') || searchParams.get('preservation'))

		# Negotiation
		categories.push(categories_param) if categories_param
		subcategories.push(subcategories_param) if subcategories_param
		subcategory_types.push(subcategory_types_param) if subcategory_types_param
		preservations.push(preservations_param) if preservations_param

		page |= parseInt searchParams.get('page')
		keywords = searchParams.get('keyword')
		discounts |= parseInt searchParams.get('discounts')
		novelties |= parseInt searchParams.get('novelties')
		{
			products: []
			filter_interactions: 1
			quantity: 1
			subcategories: []
			subcategory_types: []
			subcategory_type_names: []
			subcategory_names: []
			list_mode: 'grid'
			page
			loading: true
			search_filters: {
				novelties
				categories
				discounts
				keywords
				subcategories
				subcategory_types
				preservations
				price_from: ''
				price_to: ''
			}
			sort
		}
	new Vue {
		el
		data
		mounted: ->
			@getSubcategories(@search_filters.categories, false)
			@getSubcategory_types(@search_filters.subcategories, false)
		methods: {
			toggleListMode: ->
				@list_mode = if @list_mode is 'grid' then 'list' else 'grid'
				@filtersChanged()
			fetchProducts
			AddCart
			Favorite
			filtersChanged: ->
				@filter_interactions++
				@page = 1
				@fetchProducts()
			toggleProperty: (property) ->
				old_value = @search_filters[property]
				@search_filters[property] = !old_value
			assignProperty: (property, value) ->
				@search_filters[property] = value
			clearProperty: (property, reload = true) ->
				@search_filters[property] = '';
				@fetchProducts() if reload
			infiniteHandler: ($state) ->
				@fetchProducts('infinite', $state)
			resetState: ->
				history.pushState({}, 'Ver Todos Nuestros Productos', document.location.origin + document.location.pathname)
				Object.assign @$data, data()
			getSubcategories: (categories, launch_control = true) ->
				@search_filters.subcategories = [] if launch_control
				subcats = []
				if categories.length
					for category in categories
						AxiosRequest("/categories/#{category}.json").then ({ data }) =>
							subcats.push(data...)
							@subcategories = UniqBy(subcats, 'id')
				else
					@subcategories = []
			getSubcategory_types: (subcategories, launch_control = true) ->
				@search_filters.subcategory_types = [] if launch_control
				subcat_types = []
				if subcategories.length
					for subcategory in subcategories
						AxiosRequest("/subcategories/#{subcategory}.json").then ({ data }) =>
							subcat_types.push(data...)
							@subcategory_types = UniqBy(subcat_types, 'id')
				else
					@subcategory_types = []
			sortControl: (order) ->
				@sort = order
				@filtersChanged()
		}
		watch: {
			'search_filters.categories': (categories) ->
				@filtersChanged()
				@getSubcategories(categories)
			'search_filters.subcategories': (subcategories) ->
				@filtersChanged()
				@getSubcategory_types(subcategories)
			'search_filters.subcategory_types': -> @filtersChanged()
			'search_filters.preservations': -> @filtersChanged()
			'search_filters.price_from': -> @filtersChanged()
			'search_filters.price_to': -> @filtersChanged()
			'search_filters.discounts': -> @filtersChanged()
			'search_filters.novelties': -> @filtersChanged()
			'search_filters.keywords': -> @filtersChanged()
		}
		components: {
			InfiniteLoading
			VueAutonumeric
		}
		computed: {
			active_price_filters: -> @search_filters.discounts || @search_filters.price_from || @search_filters.price_to
			active_filters: ->
				f = @search_filters
				f.discounts || f.price_from || f.price_to || f.preservations.length || f.categories.length || f.keywords || f.novelties
		}
		filters: {
			toCurrency
		}
	}
