import AxiosRequest from "../../utils/axios-request"
import SecureLS from 'secure-ls';

export default (uid, product) ->
  id = parseInt product.idproduct
  idcustomer = uid
  dat = favourite: {product_id: id, customer_id: idcustomer}
  AxiosRequest('/customers/favourites', dat, 'post').then ({data}) ->
    Swal.fire('Listo', data.message, 'success', timer: 10000).then ->
      console.info 'success'
